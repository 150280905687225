import BigNumber from 'bignumber.js';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { useState } from 'react';
import {
  ExperianSvg,
  ExperianSvgSmall,
  SavillsSvg,
  SavillsSvgSmall,
  WallexSvg,
  WallexSvgSmall,
} from '../../static/svg/companySvgCom';
import CommonBanner from '../components/banner/CommonBanner';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import EligibilityForm from '../elements/EligibilityForm';
import InvoicePaidVideo from '../elements/InvoicePaidVideo';
import SectionTitle from '../elements/SectionTitle';

const InvoicePaid = () => {
  // 取分类 和 FAQ数据
  const { allStrapiFaq } = useStaticQuery(graphql`
    query {
      allStrapiFaq(
        limit: 4
        filter: { Type: { id: { eq: "61c7a797-1c5a-506e-beb3-e7bddadb5a87" } } }
      ) {
        edges {
          node {
            id
            Answer {
              data {
                Answer
              }
            }
            Question
          }
        }
      }
    }
  `);

  // Invoice Paid Calculator
  const rate = (0.15 * 60) / 360;
  const [invoiceAmount, setInvoiceAmount] = useState(10000);

  return (
    <Layout>
      <Seo
        title="InvoicePaid+ | Smarter way to manage your cash and finance"
        description="Cash management tool helping businesses better manage cash, increase business certainty and improve returns"
      />
      <main className="main-wrapper">
        <HeaderCommon />

        {/* InvoicePaid */}
        <CommonBanner>
          <div className="invoice-wrap">
            <div className="content-1 invoice-mobile">
              <div className="-right">
                <img
                  className="light-mode line1"
                  src="/images/invoicepaid-img-1.png"
                  style={{ width: 215 }}
                />
                <img
                  className="light-mode line2"
                  src="/images/invoicepaid-img-2.png"
                  style={{ width: 145 }}
                />
              </div>
              <div className="-left">
                <img className="light-mode" src="/images/invoicepaid-img.png" />
                <p className="-title">Smarter way to manage your cash</p>
                <div className="des-wrap">
                  <p className="-description">
                    A cash management tool designed with industry partners and experts, to help
                    companies better manage their cash, increase business certainty and improve
                    their returns
                  </p>
                  <img
                    className="light-mode line3"
                    src="/images/invoicepaid-svg.png"
                    style={{ width: 136, height: 125 }}
                  />
                </div>
                <EligibilityForm />
                <InvoicePaidVideo isMobile />
              </div>
            </div>
            <div className="content-1">
              <div className="-left">
                <img className="light-mode" src="/images/invoicepaid-img.png" />
                <p className="-title">Smarter way to manage your cash</p>
                <p className="-description">
                  A cash management tool designed with industry partners and experts, to help
                  companies better manage their cash, increase business certainty and improve their
                  returns
                </p>
                <div className="row">
                  <div className="col-8 -btns">
                    <EligibilityForm />
                    {/* <Link className="banco-btn btn-borderd btn-fluid" to="/contact">
                      See how invoicepaid+ works
                    </Link> */}
                    <InvoicePaidVideo isMobile={false} />
                  </div>
                </div>
              </div>
              <div className="-right">
                <img
                  className="light-mode line1"
                  src="/images/invoicepaid-img-1.png"
                  style={{ width: 288 }}
                />
                <img
                  className="light-mode line2"
                  src="/images/invoicepaid-img-2.png"
                  style={{ width: 339 }}
                />
                <img
                  className="light-mode line3"
                  src="/images/invoicepaid-svg.png"
                  style={{ width: 247 }}
                />
              </div>
            </div>

            <p className="icon-title">Designed to be different. For the industry</p>
            <div className="icon-wrap invoice-mobile">
              {SavillsSvgSmall({})}
              {ExperianSvgSmall({})}
              <img
                className="light-mode line1"
                src="/images/icon-myinfo.png"
                style={{ width: 44 }}
              />
              {WallexSvgSmall({})}
            </div>
            <div className="icon-wrap">
              {SavillsSvg({})}
              {ExperianSvg({})}
              <img
                className="light-mode line1"
                src="/images/icon-myinfo.png"
                style={{ width: 91 }}
              />
              {WallexSvg({})}
            </div>

            <div className="section section-padding">
              <div className="container">
                <div className="row invoice-calculator">
                  <div className="col-lg-8 calculator">
                    <h3>
                      Adjust the sliders to see how much you can get upfront and the estimated
                    </h3>
                    <h4>Convenience fee</h4>
                    <label htmlFor="invoiceAmount" className="form-label">
                      Invoice Amount (SGD)
                      <span>${new BigNumber(invoiceAmount).toFormat()}</span>
                    </label>

                    <Slider
                      className="form-range"
                      value={invoiceAmount}
                      onChange={(value) =>
                        setInvoiceAmount(Array.isArray(value) ? value[0] : value)
                      }
                      min={0}
                      step={1000}
                      max={1000000}
                    />

                    <p className="description">
                      *The figures generated by this calculator are intended as a guide only, based
                      on a 60 days payment terms and varies based on the information you provide.
                      This shall not, and is not intended to, constitute a commitment that rates
                      offered to you will necessarily be better than those generated by the
                      calculator, and, in particular, is not a commitment to provide any financing.
                      Rates will be determined according to, among other things, our analysis of
                      your risk profile. Any financing is subject to such terms and conditions,
                      disclaimers and lending criteria as may be set by InvoicePaid+ from time to
                      time.
                    </p>
                  </div>
                  <div className="col-lg-4 result">
                    <h3>Calculation</h3>
                    <div className="result-row">
                      <div className="item">How much you could get now</div>
                      <div className="value">
                        $
                        {new BigNumber(0.8)
                          .times(1 - rate)
                          .times(invoiceAmount)
                          .toFormat()}
                      </div>
                    </div>
                    <div className="result-row">
                      <div className="item">Plus, when invoices paid</div>
                      <div className="value">
                        ${new BigNumber(0.2).times(invoiceAmount).toFormat(2)}
                      </div>
                    </div>
                    <div className="result-fee-row">
                      <div className="item">Your estimated convenience fee</div>
                      <div className="value">
                        ${new BigNumber(rate).times(invoiceAmount).times(0.8).toFormat(2)}
                      </div>
                    </div>
                    <div className="row row-8">
                      <button
                        className="banco-btn btn-fill-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#eligibilityForm"
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                  <p className="description des-mobile">
                    *The figures generated by this calculator are intended as a guide only, based on
                    information that you provide. This shall not, and is not intended to, constitute
                    a commitment that rates offered to you will necessarily be better than those
                    generated by the calculator, and, in particular, is not a commitment to provide
                    any financing. Rates will be determined according to, among other things, our
                    analysis of your risk profile. Any financing is subject to such terms and
                    conditions, disclaimers and lending criteria as may be set by InvoicePaid+ from
                    time to time.
                  </p>
                </div>
              </div>
            </div>

            <p className="-breadcrumb">Take control of your cash</p>
            <p className="-sub-breadcrumb">
              Empowering businesses with an easy, honest and flexible service. Gain control of your
              cash flow by getting all invoices paid on time, every time.
            </p>
            <div className="row content-2">
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/easy-svg.png" style={{ width: 90 }} />
                </div>
                <div>
                  <p className="-title">Easy</p>
                  <p className="-sub-title">Smart Application. Minimal documents</p>
                  <p className="-description">
                    Enabled by technology, you can <span>sign up</span> today in the{' '}
                    <span>next 10 mins</span> with little documents to provide.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>
                  <img className="light-mode" src="/images/honest-svg.png" style={{ width: 106 }} />
                </div>
                <div>
                  <p className="-title">Honest</p>
                  <p className="-sub-title">All in single fee</p>
                  <p className="-description">
                    <span>No other hidden fees. </span> A<span> convenience fee</span> when you use
                    the service. Better cashflow management with predictability.
                  </p>
                </div>
              </div>
              <div className="card col-4">
                <div>
                  <img
                    className="light-mode"
                    src="/images/flexible-svg.png"
                    style={{ width: 110 }}
                  />
                </div>
                <div>
                  <p className="-title">Flexible</p>
                  <p className="-sub-title">Grows with your business</p>
                  <p className="-description">
                    Just <span>add and manage</span> your <span>new or existing</span> contracts
                    whenever you need. Focus on growing your business.
                  </p>
                </div>
              </div>
            </div>

            <p className="-breadcrumb">
              Instant Approval. Same day Funding Easy online application.
            </p>
            <p className="-sub-breadcrumb">3 easy steps to sign up for InvoicePaid+</p>
            <div className="row content-3">
              <div className="col">
                <div className="circle">1</div>
                <div>
                  <p className="-title">Check eligibility</p>
                  <p className="-description">
                    Find out whether you are eligible in 60 seconds with no commitment
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="circle">2</div>
                <div>
                  <p className="-title">Apply online</p>
                  <p className="-description">
                    When you are ready, sign up at banco, complete your online application with help
                    from the banco team
                  </p>
                </div>
              </div>
              <div className="col">
                <div className="circle">3</div>
                <div>
                  <p className="-title">Submit Invoice</p>
                  <p className="-description">
                    Digitally sign your documents, submit your invoices and funds will be deposited
                    to your account on the same day.*
                  </p>
                </div>
              </div>
            </div>

            <div className="row row-8 justify-content-md-center">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <EligibilityForm />
              </div>
            </div>
          </div>
        </CommonBanner>

        <div className="section section-padding section-faq">
          <div className="container">
            <SectionTitle title="FAQ" />
            <div className="row row-8 pb--70">
              {console.log(allStrapiFaq.edges, 'allStrapiFaq.edges')}
              {allStrapiFaq.edges.map(({ node }: any, index: number) => (
                <div className="col-md-6 faq-item" key={node.id}>
                  <div>
                    <a
                      className="collapsed collapse-header"
                      data-bs-toggle="collapse"
                      href={`#question${index}`}
                    >
                      <h3>Q: {node.Question}</h3>
                      <i className="triangle-icon" />
                    </a>
                    <div
                      className="collapse"
                      id={`question${index}`}
                      dangerouslySetInnerHTML={{
                        __html: node.Answer.data.Answer.replace(
                          /banco-website-static.s3.ap-southeast-1.amazonaws.com/g,
                          'website-static.banco.com.sg',
                        ),
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="row row-8 justify-content-md-center">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <Link className="banco-btn btn-borderd btn-fluid" to="/faq">
                  See more FAQs
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </Layout>
  );
};

export default InvoicePaid;
